/**
 * @file 定数
 * @author Ozaki Takao
 */
(function () {
  'use select';

  if (typeof window.Const === 'undefined') {
    window.Const = {};
  }
  var constant = {
    /** アプリバージョン */
    RELEASE_VERSION: '1.2.0.0',

    /** API呼出URLのプリフィクス文字 */
    API_URL_PREFIX: '',

    /** HTTPステータスコード */
    STATUS_CODE: {
      OK: 200, // リクエスト成功（Status Code : 200）
      BAD_REQUEST: 400, // リクエスト不正（Status Code : 400）
      UNAUTHORIXED: 401, // 認証エラー（Status Code : 401）
      NOT_FOUND: 404, // 未検出（Status Code : 404）
      CONFLICT: 409, // データ不整合（Status Code : 409）
      TOO_MANY_REQUESTS: 429, // 同時実行上限（Status Code : 429）
      SERVER_ERROR: 500, // サーバーエラー（Status Code : 500）
      BAD_GATEWAY: 502, // 不正なゲートウェイ（Status Code : 502）
      GATEWAY_TIMEOUT: 504 // ゲートウェイタイムアウト（Status Code : 504）
    },
    /** 結果コード */
    RESULT_CODE: {
      OK: '200', // リクエスト成功（Result Code : 200）
      NO_CONTENT: '204', // 検索結果なし（Result Code : 204）
      BAD_REQUEST: '400', // リクエスト不正（Result Code : 400）
      UNAUTHORIXED: '401', // 認証エラー（Result Code : 401）
      NOT_FOUND: '404', // [端末情報]未検出（Result Code : 404）
      NOT_FOUND_STORE_GROUP: '404_store_group', // [店舗グループ情報]未検出（Result Code : 404）
      NOT_FOUND_STORE_GROUP_DEST: '404_store_group_dest', // [店舗グループ情報]未検出（Result Code : 404）
      NOT_FOUND_USER_GROUP: '404_user_group', // [ユーザーグループ情報]未検出（Result Code : 404）
      NOT_FOUND_USER: '404_user', // [ユーザー情報]未検出（Result Code : 404）
      EXIST_PRE_TASK: '400_pre_task', // [実行前タスク情報]存在（Result Code : 400）
      CONFLICT: '409', // データ不整合（Result Code : 409）
      TOO_MANY_REQUESTS: '429', // 同時実行上限（Result Code : 429）
      SERVER_ERROR: '500' // サーバーエラー（Result Code : 500）
    },

    /** ユーザー情報を格納するSessionStorageキー */
    SESSION_KEY: {
      USER_INFO: 'userInfo', // ユーザー情報
      USER_GROUP_TEREMIANL: 'userGroupTerminal', // 端末のユーザグループ情報
      TERMINAL_DETAIL_PREV_PAGE: 'terminalDetailPrevPage' // 個別端末情報の前画面情報
    },
    /** [session]ユーザー情報内のキー */
    SESSION_USER_INFO: {
      /** ユーザーID */
      USER_ID: 'userId',
      /** ユーザー種別 */
      USER_TYPE: 'userType',
      /** ユーザー名称 */
      USER_NAME: 'userName',
      /** メールアドレス */
      MAIL_ADDRESS: 'mailAddress',
      /** ユーザーグループID */
      USER_GROUP_ID: 'userGroupId'
    },

    /** ユーザー種別 */
    USER_TYPE: {
      /** 管理 */
      ADMIN: 1,
      /** 運用 */
      OPERATION: 2,
      /** 設定(代理店) */
      AGENT: 3,
      /** 使用 */
      GENERAL: 4
    },

    /** ユーザー種別（名称） */
    USER_TYPE_NAME: {
      /** 管理 */
      1: 'システム管理者',
      /** 運用 */
      2: 'サービス運用者',
      /** 設定(代理店) */
      3: 'コンテンツパートナー',
      /** 使用 */
      4: 'エンドユーザー'
    },

    /** 画面デザイン種別 */
    DESIGN_TYPE: {
      /** 通常 */
      NORMAL: 'normal',
      /** 端末操作（使用ユーザー向け） */
      OPERATION: 'operation'
    },

    /** 日付書式 */
    DATE_TIME_FORMAT: {
      /** 年月日時分秒 */
      YMD_HMS: 'ymd_hmd',
      /** 年月日時分 */
      YMD_HM: 'ymd_hm',
      /** 年月日 */
      YMD: 'ymd'
    },

    /** フラグ */
    FLAG: {
      OFF: 0,
      ON: 1
    },

    /** メニュー */
    MENU: {
      /** ホーム */
      HOME: {
        ID: 'menu-home',
        PATHNAME: '/home/home.html'
      },
      /** タスク一覧 */
      TASK: {
        ID: 'menu-task',
        PATHNAME: '/task/task.html'
      },
      /** 端末管理 */
      TERMINAL: {
        ID: 'menu-terminal',
        PATHNAME: '/terminal/terminal.html'
      },
      /** スケジュール管理 */
      SCHEDULE: {
        ID: 'menu-schedule',
        PATHNAME: '/schedule/schedule.html'
      },
      /** 店舗グループ管理 */
      STORE_GROUP: {
        ID: 'menu-store-group',
        PATHNAME: '/store_group/store_group.html'
      },
      /** ユーザー管理 */
      USER: {
        ID: 'menu-user',
        PATHNAME: '/user/user.html'
      },
      /** マスタ管理 */
      MASTER: {
        ID: 'menu-master',
        PATHNAME: '/master/master.html'
      },
      /** 端末操作（メニュー非表示） */
      OPERATION: {
        ID: 'menu-operation',
        PATHNAME: '/operation/operation.html'
      }
    },

    /** ユーザー */
    USER: {
      ASSIGN_TERMINAL_LIMIT: 20, // １ユーザーあたり割当可能端末数の上限
      USER_GROUP_TERMINAL_UPDATE_TERMINAL_CNT: 10 // [ユーザーグループ所属端末異動API]１リクエストでの端末処理件数
    },

    /** 端末 */
    TERMINAL: {
      USER_ACCOUNT_LIMIT: 3, // 「端末1台あたりの使用ユーザー数」の上限
      /** 端末状態 */
      STATE: {
        NORMAL: 1, // 正常
        NOT_ALIVE: 2, // 生存確認失敗
        FAULT: 3 // 機器異常発生
      },
      /** 実行中ステータス */
      RUNNING_STATUS: {
        /** スケジュール運用中 */
        SCHEDULE: 0,
        /** スケジュール変更機能にてスケジュール運用中 */
        SCHEDULE_CHANGED: 1,
        /** ダイレクト運用中 */
        DIRECT: 2,
        /** 待機中 */
        WAIT: 3
      },
      /** リモート制御 */
      REMOTE_CONTROL: {
        /** 無効 */
        DISABLED: {
          VAL: 0,
          DISP: '無効'
        },
        /** 有効 */
        ENABLED: {
          VAL: 1,
          DISP: '有効'
        }
      }
    },

    /** タスク */
    TASK: {
      /** 実施状況 */
      EXEC_STATE: {
        /** 実行前 */
        BEFORE: '1',
        /** 実行済み */
        AFTER: '2'
      },
      /** 処理状況 */
      STATUS: {
        /** 未実行 */
        UNEXEC: 1,
        /** 同期中 */
        SYNC: 2,
        /** 完了 */
        SUCCESS: 3,
        /** 失敗 */
        FAIL: 4,
        /** キャンセル */
        CANCEL: 5
      },
      /** 種別 */
      TYPE: {
        SCHEDULE_UPDATE: {
          VAL: 1,
          DISP: 'スケジュール更新'
        },
        SCHEDULE_DELETE: {
          VAL: 2,
          DISP: 'スケジュール削除'
        },
        DIRECT_PLAY_SETTING: {
          VAL: 3,
          DISP: 'ダイレクト再生設定'
        },
        TERMINAL_SETTING: {
          VAL: 4,
          DISP: '機器設定変更・取得'
        },
        GET_OPERATION_LOG: {
          VAL: 5,
          DISP: '動作ログ取得'
        },
        FIRMWARE_UPDATE: {
          VAL: 6,
          DISP: 'ファームウェア更新'
        },
        RESTART: {
          VAL: 7,
          DISP: '再起動'
        },
        SCHEDULE_CHANGE: {
          VAL: 8,
          DISP: 'リモートスケジュール変更開始'
        },
        SCHEDULE_RELEASE: {
          VAL: 9,
          DISP: 'リモートスケジュール変更解除'
        },
        DIRECT_PLAY_START: {
          VAL: 10,
          DISP: 'リモートダイレクト再生開始'
        },
        DIRECT_PLAY_STOP: {
          VAL: 11,
          DISP: 'リモートダイレクト再生停止'
        },
        GET_SCHEDULE_LIST: {
          VAL: 12,
          DISP: 'スケジュールリスト取得'
        },
        CONTENTS_DOWNLOAD: {
          VAL: 13,
          DISP: 'コンテンツダウンロード'
        },
        CONTENTS_CONFIRM: {
          VAL: 14,
          DISP: 'コンテンツ確認'
        },
        CONTENTS_DELETE: {
          VAL: 15,
          DISP: 'コンテンツ削除'
        }
      },
      /** 同期タイミングフラグ */
      SYNC_TIMING_FLAG: {
        /** 今すぐ同期 */
        NOW: 0,
        /** 時間指定同期 */
        SPECIFIED: 1
      },
      /** コンテンツダウンロード種別 */
      CONTENTS_DOWNLAOD_TYPE: {
        NOT_EXIST: 0, // 同名のコンテンツが存在しない
        ALWAYS: 1, // 同名のコンテンツの存在に関わらない
        NEWER: 2 // 同名のコンテンツより新しい
      }
    },
    /** ダイレクト再生 */
    DIRECT: {
      CONTENTS_MAX_CNT: 200 // コンテンツファイルの最大数
    },
    /** スケジュール */
    SCHEDULE: {
      /** 優先度 */
      PRIORITY: {
        TOP: { // 最優先
          VAL: 0,
          DISP: '最優先'
        },
        HIGH: { // 高
          VAL: 1,
          DISP: '高'
        },
        MIDDLE: { // 中
          VAL: 2,
          DISP: '中'
        },
        LOW: { // 低
          VAL: 3,
          DISP: '低'
        }
      },
      DELIVERY_TERMINAL_MAX_CNT: 200 // 配信端末の最大数
    },
    /** 店舗グループ */
    STORE_GROUP: {
      /** グループなしID */
      ID_DEFAULT: 'STRG0000000000'
    },
    /** 署名付きURL取得：ファイル種別 */
    SIGNED_URL_FILE_TYPE: {
      TASK_RESPONSE: 1, // タスク結果情報ファイル
      TERMINAL_HISTORY_LOG: 2, // 端末動作ログファイル
      TERMINAL_EVENT_NOTICE: 3, // イベント通知ファイル 
    },
    /** 死活監視間隔 */
    ALIVE_CHECK_INTERVAL: {
      NO_CHECK: 0, // しない
      ONE_HOURS: 60, // 1時間
      TWO_HOURS: 120, // 2時間
      THREE_HOURS: 180, // 3時間
      SIX_HOURS: 360, // 6時間
      TWELVE_HOURS: 720, // 12時間
      ONE_DAY: 1440 // 24時間
    },
    /** 死活監視間隔 表示テキスト */
    ALIVE_CHECK_INTERVAL_NAME: {
      0: 'しない', // しない
      60: '1時間', // 1時間
      120: '2時間', // 2時間
      180: '3時間', // 3時間
      360: '6時間', // 6時間
      720: '12時間', // 12時間
      1440: '24時間' // 24時間
    },
    /** 端末状態アイコン */
    TERMINAL_STATE_ICON: {
      2: '/common/img/ico_symbol-01.png',
      3: '/common/img/ico_symbol-01.png',
      4: '/common/img/ico_symbol-01.png',
      5: '/common/img/ico_symbol-02.png',
      6: '/common/img/ico_symbol-03.png'
    },
    /** 異常レベルアイコン */
    ERROR_LEVEL_ICON: {
      '異常': '/common/img/ico_symbol-01.png',
      '警告': '/common/img/ico_symbol-02.png',
      '情報': '/common/img/ico_symbol-03.png'
    },
    /** コンテンツ管理 */
    CONTENTS: {
      /** コンテンツファイル種別 */
      FILE_TYPE: {
        /** 端末内の全コンテンツ */
        ALL: {
          VAL: '0',
          DISP: '端末内の全コンテンツ'
        },
        /** スケジュールファイル */
        SCHEDULE_FILE: {
          VAL: '1',
          DISP: 'スケジュールファイル'
        },
        /** 番組ファイル */
        PROGRAM_FILE: {
          VAL: '2',
          DISP: '番組ファイル'
        },
      },
      /* 番組ファイルの項目名 ※一部 */
      PM_FILE_ITEM_NAME: {
        PROGRAM_VERSION: 'program_version', // 「番組記述バージョン」
        PROGRAM_TYPE: 'program_type', // 「番組種別」
        PROGRAM_NAME: 'program_name', // 「番組名称」
        CONTENTS_OWNER_CODE_AND_PMNUM: 'contents_owner_code_and_pmnum', // 「コンテンツ所有者コード＋番組管理番号」
        CONTENTS_LIST: 'contents_list', // 「コンテンツ所有者コード＋番組管理番号」
        CONTENTS_NAME: 'contents_name', // 「楽曲・音声名称」
        FILE_NAME: 'file_name', // 「楽曲・音声ファイル名」
        FTP_PATH: 'ftp_path', // 「ＦＴＰパス」
        SUM: 'sum', // 「サム値」
        CHECK_SUM: 'check_sum' // 「チェックサム値」
      }
    }
  };

  Object.keys(constant).forEach(key => {
    window.Const[key] = constant[key];
  });
}());
